<template>
  <div>
    <AppSmartList
      :breadcrumbData="breadcrumbData"
      :accessRights="accessRights"
      :filters="filters"
      :actions="actions"
      :tableHeaders="headers"
    ></AppSmartList>
  </div>
</template>

<script>
  import AppSmartList from '@/views/components/bases/smart/AppSmartList.vue';
  import {
    ROUTE_NAME,
    MODULE,
    ACTION,
    SMART_FORM_FIELD_TYPE,
    APP_COMPONENT_NAME
  } from '@/core/constants';
  import { i18nHelper } from '@/core/utils';
  import { listService } from '@/core/services';
  import {
    CHAT_GET_CHATS,
    CHAT_INITIAL_GET_CHATS_STATE
  } from '@/core/store/chat.module';

  export default {
    name: 'ChatHistoryList',
    components: {
      AppSmartList
    },
    data: () => ({
      breadcrumbData: {
        breadcrumbs: [
          {
            title: i18nHelper.getMessage('label.chatHistory'),
            route: { name: ROUTE_NAME.ACCOUNT_VERIFICATION }
          },
          { title: i18nHelper.getMessage('label.chatHistoryList') }
        ]
      },
      accessRights: {
        moduleName: MODULE.CHAT,
        actions: {
          read: ACTION.CHAT_READ
        }
      },
      filters: [
        {
          label: 'name',
          key: 'name',
          value: null
        },
        {
          label: 'active',
          key: 'active',
          value: null,
          options: listService.getStatusList(),
          optionLabel: 'text',
          type: SMART_FORM_FIELD_TYPE.SELECT
        }
      ],
      actions: {
        viewList: {
          module: 'chat',
          state: 'chats',
          action: CHAT_GET_CHATS,
          initialStateAction: CHAT_INITIAL_GET_CHATS_STATE,
          title: i18nHelper.getMessage('label.getChats')
        },
        viewDetails: {
          routeName: ROUTE_NAME.CHAT_HISTORY_DETAILS
        }
      },
      headers: [
        {
          key: 'no',
          name: 'no',
          active: true,
          sortable: true
        },
        {
          key: 'name',
          name: 'name',
          active: true,
          sortable: true,
          class: 'min-w-150px'
        },
        {
          key: 'castingRole',
          name: 'castingRole',
          active: true,
          sortable: true
        },
        {
          key: 'isActive',
          name: 'active',
          active: true,
          sortable: true,
          layout: APP_COMPONENT_NAME.APP_ACTIVATION_STATUS_LABEL
        },
        {
          key: 'createdAt',
          name: 'createdAt',
          active: true,
          sortable: true
        },
        {
          key: 'actions',
          name: 'actions',
          active: true,
          action: true,
          layout: APP_COMPONENT_NAME.APP_TABLE_ACTIONS
        }
      ]
    })
  };
</script>

<style></style>
